import { useEffect } from "react";
import "../../style/pages/Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faApple, faWindows } from "@fortawesome/free-brands-svg-icons";

export default function Home() {
    useEffect(() => {
        document.title = "Topassist - Contact";
    }, []);

    return (
        <div className="contact-page panel-page">
            <meta name="description" content="Topassist - Contact informatie" />
            <div className="eye-catcher-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2480.03777605147!2d4.467331!3d51.567541!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c417e442a369bb%3A0xb368ecf4bff7e0c9!2sTopassist!5e0!3m2!1sen!2snl!4v1733952777940!5m2!1sen!2snl"
                    title="map"
                    allowFullScreen=""
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <div className="content">
                <div className="panel over-eye-catcher contact-information">
                    <h1>informatie</h1>
                    <div>
                        <div>
                            <a href="tel:0165 745 295">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                </span>
                                <span>0165-745295</span>
                            </a>
                            <a href="mailto:info@topassist.nl">
                                <span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                                <span>info@topassist.nl</span>
                            </a>
                            <a href="https://maps.app.goo.gl/zuAMGLuQkb3hg1Jz5" target="_blank" rel="noreferrer">
                                <span>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </span>
                                <span>
                                    Argon 15o
                                    <br />
                                    4751 XC Oud Gastel
                                </span>
                            </a>
                        </div>
                        <div>
                            <span>
                                <span>KvK Breda:</span>
                                <span>20087084</span>
                            </span>
                            <span>
                                <span>BTW:</span>
                                <span>NL8091.70.486.B01</span>
                            </span>
                            <span>
                                <span>Bank:</span>
                                <span>NL25 ABNA 0518 8187 72</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="panel teamviewer">
                    <h1>Teamviewer</h1>
                    <h2>Desktop</h2>
                    <div className="buttons">
                        <a href="/downloads/TeamViewerQS.exe">
                            <FontAwesomeIcon icon={faWindows} />
                            <span>Windows download</span>
                        </a>
                        <a href="/downloads/TeamViewerQS.dmg">
                            <FontAwesomeIcon icon={faApple} />
                            <span>MacOS download</span>
                        </a>
                    </div>
                </div>
                <div className="panel form-container">
                    <h1>Contact formulier</h1>
                    <form action="https://formsubmit.co/45a98ffe7e18c85d7853722ff0933c3f" method="POST" autoComplete="off">
                        <label htmlFor="name">Naam</label>
                        <input type="text" id="name" name="Naam" required />
                        <label htmlFor="name">Bedrijfsnaam</label>
                        <input type="text" id="company-name" name="Bedrijfsnaam" required />
                        <label htmlFor="name">Bedrijfslocatie</label>
                        <input type="text" id="company-location" name="Bedrijfslocatie" required />
                        <label htmlFor="mail">E-mail</label>
                        <input type="email" id="email" name="email" required />
                        <label htmlFor="subject">Waar kunnen wij u mee van dienst zijn?</label>
                        <select type="text" id="subject" name="IT-oplossing" required>
                            <option hidden value="">
                                -- Kies een IT-oplossing --
                            </option>
                            <option value="Advies">Advies</option>
                            <option value="Werkplekbeheer">Werkplekbeheer</option>
                            <option value="Abonnementenbeheer">Abonnementenbeheer</option>
                            <option value="Netwerk en systeembeheer">Netwerk en systeembeheer</option>
                            <option value="Telefonie en VoIP">Telefonie en VoIP</option>
                            <option value="Data beveiliging">Data beveiliging</option>
                            <option value="Back-up en opslag van data">Back-up en opslag van data</option>
                        </select>
                        <label htmlFor="content">Inhoud</label>
                        <textarea id="content" name="Tekst" />

                        <hr />

                        <div>
                            <input id="accept" type="checkbox" required />
                            <label htmlFor="accept">
                                Ik ga akkoord dat de gegevens gebruikt kunnen worden voor de doeleinden zoals beschreven
                                in onze algemene voorwaarden en privacyverklaring.
                            </label>
                        </div>
                        <button type="submit">
                            <FontAwesomeIcon icon={faPaperPlane} />
                            Sturen
                        </button>
                        <input type="hidden" name="_next" value="http://topassist.nl/confirm"></input>
                        <input
                            type="hidden"
                            name="_autoresponse"
                            value="Bedankt voor je contact aan Topassist. We zullen zo snel mogelijk reageren!"
                        ></input>
                    </form>
                </div>
            </div>
        </div>
    );
}
