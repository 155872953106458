/* eslint jsx-a11y/heading-has-content: 0 */

import { useEffect } from "react";
import "../../style/pages/Vacancies.scss";

const vacancies = [
    {
        title: "Helpdesk&shy;medewerker / BBL-er",
        subTitle:
            "Ben jij die beginnend ICT'er die graag met alle facetten van ICT in aanraking komt, en draai je je hand niet om voor de meest uiteenlopende taken? Dan zou je wel eens goed in ons team kunnen passen!",
        contentHtml: (
            <>
                <div>
                    <h2 className="sub-header">Wat wij bieden</h2>
                    <ul>
                        <li>Een gevarieerde verzameling van werkzaamheden, je zal je niet snel gaan vervelen.</li>
                        <li>Gezellige en behulpzame collega's met tijd voor een grapje en een praatje.</li>
                        <li>Een prettige, informele en toch professionele werksfeer.</li>
                        <li>Vrijmibo, en voor een lichte lunch wordt altijd gezorgd.</li>
                        <li>Een marktconform salaris met aantrekkelijke secundaire arbeidsvoorwaarden.</li>
                        <li>Goede pensioenvoorziening.</li>
                        <li>Kansen om jezelf te ontwikkelen binnen ons groeiende bedrijf.</li>
                    </ul>
                </div>
                <div>
                    <h2 className="sub-header">Geïnteresseerd of vragen?</h2>
                    <ul>
                        <li>
                            Bel ons op <a href="tel:0165 745 295">0165-745295</a>, en vraag naar Robin of Piet.
                        </li>
                        <li>
                            Stuur direct een e-mail naar{" "}
                            <a href="mailto:r.walraven@topassist.nl">r.walraven@topassist.nl</a>.
                        </li>
                    </ul>
                </div>
            </>
        ),
    },
];

export default function Vacancies() {
    useEffect(() => {
        document.title = "Topassist - Vacatures";
    }, []);

    return (
        <div className="vacancies-page panel-page">
            <meta name="description" content="Topassist - Vacatures" />
            <div className="eye-catcher-container">
                <video autoPlay playsInline loop muted>
                    <source src="/images/background.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="content">
                <div className="panel over-eye-catcher">
                    <h1>
                        Werken bij <span className="accented">Topassist</span>
                    </h1>
                    <h3>
                        Ben jij op zoek naar een gevarieerde en interessant nieuwe baan binnen IT?
                        <br />
                        Kijk dan hier onder naar onze openstaande vacatures. Hopelijk zien we jou snel bij ons op
                        kantoor!
                    </h3>
                </div>

                {vacancies.map((v, i) => (
                    <div className="panel vacancy" key={`vacancy-${i}`}>
                        <div>
                            <h1 dangerouslySetInnerHTML={{ __html: v.title }}></h1>
                            <h4 dangerouslySetInnerHTML={{ __html: v.subTitle }}></h4>
                        </div>
                        {v.contentHtml}
                    </div>
                ))}
            </div>
        </div>
    );
}
