import { createRef, Fragment, useEffect, useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../style/components/DropdownList.scss";

function groupBy(arr, key) {
    return arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {});
}

function capitalizeFirst(text) {
    return text
        .split(" ")
        .map((s, i) => (i === 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s))
        .join(" ");
}

export default function DropdownList(props) {
    const entriesByCategory = groupBy(
        props.entries.map((e) => ({
            ...e,
            category: capitalizeFirst((e.category ||= "Other").toLocaleLowerCase("en")),
        })),
        (e) => e.category
    );
    const entryRefs = Object.entries(entriesByCategory).map(([_, entries]) => entries.map(() => createRef()));

    const [openSectionTimeout, setOpenSectionTimeout] = useState(null);

    const defaultIndex = { i: -1, j: -1 };
    const [openedIndex, setOpenedIndex] = useState(defaultIndex);
    const isOpenedIndex = (index) => openedIndex.i === index.i && openedIndex.j === index.j;

    function openSection(selected) {
        const ref = entryRefs[selected.i][selected.j].current;
        if (!ref) return;

        const refContent = ref.querySelector(".entry-content");
        const refContentContent = ref.querySelector(".entry-content>div");
        if (!refContent || !refContentContent) return;
        refContent.style.height = `${refContentContent.offsetHeight}px`;
        setOpenSectionTimeout(setTimeout(() => (refContent.style.height = `fit-content`), 550));
    }

    function closeSection(opened) {
        const ref = entryRefs[opened.i][opened.j].current;
        if (!ref) return;

        const refContent = ref.querySelector(".entry-content");
        const refContentContent = ref.querySelector(".entry-content>div");
        if (!refContent || !refContentContent) return;

        requestAnimationFrame(() => {
            refContent.style.height = `${refContentContent.offsetHeight}px`;
            requestAnimationFrame(() => (refContent.style.height = "0"));
        });
    }

    function toggleOpenSection(i, j) {
        if (openSectionTimeout !== null) clearTimeout(openSectionTimeout);

        const selectedIndex = { i, j };
        if (isOpenedIndex(selectedIndex)) {
            closeSection(selectedIndex);
            setOpenedIndex(defaultIndex);
        } else {
            if (!isOpenedIndex(defaultIndex)) closeSection(openedIndex);
            setOpenedIndex(selectedIndex);
            openSection(selectedIndex);
        }
    }

    useEffect(() => {
        // Set opened solution from query parameter
        const queryIndex = parseInt(props.searchParams.get("opened"));
        if (queryIndex !== undefined && queryIndex > -1 && queryIndex < props.entries.length) {
            toggleOpenSection(0, queryIndex);
        }
    }, []);

    const entryElements = Object.entries(entriesByCategory).map(([category, entries], i, input) => (
        <Fragment key={`fragment-${i}`}>
            {input.length < 2 ? <></> : <h3 key={`dropdown-list-${i}`}>{category}</h3>}
            {entries.map((entry, j) => (
                <div
                    key={`dropdown-list-${i}-${j}`}
                    ref={entryRefs[i][j]}
                    className={isOpenedIndex({ i, j }) ? "entry active" : "entry"}
                >
                    <div className="title" onClick={() => toggleOpenSection(i, j)}>
                        <span>
                            <FontAwesomeIcon icon={entry.icon} />
                            <h2 dangerouslySetInnerHTML={{ __html: entry.title }}></h2>
                        </span>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                    <div className="entry-content">
                        <div>{entry.content}</div>
                    </div>
                </div>
            ))}
        </Fragment>
    ));

    return <div className="dropdown-list">{entryElements}</div>;
}
