import { NavLink } from "react-router-dom";
import TaLogo from "../components/TaLogo";
import "../../style/components/Footer.scss";

export default function Footer(_) {
    const scrollToTop = () => window.scroll({ top: 0, behavior: "smooth" });

    return (
        <footer>
            <div className="left">
                <div className="links">
                    <NavLink to="/" onClick={scrollToTop}>
                        <TaLogo className="ta-logo" />
                    </NavLink>
                    <a href="/downloads/Topassist-Voorwaarden.pdf" download>Algemene voorwaarden</a>
                </div>
            </div>
            <div className="center">
                <div>
                    <span>Argon 15o, 4751 XC</span>
                    <span>Oud Gastel</span>
                </div>
                <div>
                    <a href="tel:0165 745 295">0165-745295</a>
                    <a href="mailto:info@topassist.nl">info@topassist.nl</a>
                </div>
                <div>
                    <span>Maandag t/m vrijdag</span>
                    <span>08:30 - 17:00 uur</span>
                </div>
                <a class="mobile-el" href="/downloads/Topassist-Voorwaarden.pdf" download>Algemene voorwaarden</a>
            </div>
            <div className="mobile-right">
                <a href="https://ictwaarborg.nl/" target="_blank" rel="noreferrer">
                    <img src="https://img.nwalraven.me/topassist/logos/ict-waarborg_720" alt="ICT-waarborg logo"></img>
                </a>
                <a href="https://www.nldigital.nl/" target="_blank" rel="noreferrer">
                    <img src="/images/nl-digital.svg" alt="NL-digital logo"></img>
                </a>
            </div>
            <div className="right">
                <div className="support-downloads">
                    <span>Support op afstand</span>
                    <div className="buttons">
                        <a href="/downloads/TeamViewerQS.exe">Windows</a>
                        <hr />
                        <a href="/downloads/TeamViewerQS.dmg">MacOS</a>
                    </div>
                </div>
                <div className="logos">
                    <a href="https://ictwaarborg.nl/" target="_blank" rel="noreferrer">
                        <img
                            src="https://img.nwalraven.me/topassist/logos/ict-waarborg_720"
                            alt="ICT-waarborg logo"
                        ></img>
                    </a>
                    <a href="https://www.nldigital.nl/" target="_blank" rel="noreferrer">
                        <img src="/images/nl-digital.svg" alt="NL-digital logo"></img>
                    </a>
                </div>
            </div>
        </footer>
    );
}
