import "../../style/components/NavPicRect.scss";
import { NavLink } from "react-router-dom";

export default function NavPicRect(props) {
    return (
        <NavLink to={props.to} className="nav-pic-rect">
            <div>
                <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
            </div>
            <div className="free-pane"></div>
            <img src={`https://img.nwalraven.me/topassist/it-solutions/${props.imgFilename}_720`} alt={props.imgAlt} loading="lazy"></img>
        </NavLink>
    );
}
